import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./header.module.sass"
import { AnchorLink } from "gatsby-plugin-anchor-links";


const Header = ({ siteTitle }) => (
  <header className={styles.mainHeader}>
    <div className={styles.mainHeader_inner}>

      {/* Site title
      <h1 style={{ margin: 0 }}>
        <Link to="/">{siteTitle}</Link>
      </h1> 
      */}

      <nav className={styles.navigation}>
        <ul>
          <li><Link to={`/`}>Works</Link></li>
          <li><Link to={`/sketch`}>Sketch</Link></li>
          <li><Link to={`/about`}>About</Link></li>
          <li><AnchorLink to={`/about#contact`}>Contact</AnchorLink></li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
