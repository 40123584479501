import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styles from "./footer.module.sass"
import { Instagram, GithubAlt, Twitter, Linkedin } from '@styled-icons/fa-brands'   // Using styled-icons.js
import { Email } from '@styled-icons/material-rounded'

const Footer = ({ siteAuthor }) => (
  <footer>
    <div className={styles.footerContainer}>
      <ul className={styles.snsLinks}>
        <li><AnchorLink to={`/about#contact`}><Email size="30" /></AnchorLink></li>
        <li><a href="https://www.linkedin.com/in/k-sdi-75531b140" target="_blank"><Linkedin size="30" /></a></li>
        <li><a href="https://www.instagram.com/k_s_p_n/" target="_blank"><Instagram size="30" /></a></li>
        {/*<li><a href="https://twitter.com/_soup" target="_blank"><Twitter size="34" /></a></li>*/}
        <li><a href="https://github.com/kasipan" target="_blank"><GithubAlt size="30" /></a></li>
        {/*<li>note?</li>*/}
      </ul>

      <p className={styles.copyright}>
        © {new Date().getFullYear()} 
        {` `}
        {siteAuthor}
      </p>
    </div>
  </footer>
)

export default Footer
